import React from 'react';

function Chat() {
  return (
    <div>
      <h2>Chat</h2>
      <p>Chat with the dispatch or other drivers here.</p>
    </div>
  );
}

export default Chat;
