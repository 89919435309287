import React, { useEffect, useState } from 'react';
import moment from 'moment';
import comms from './comms'; // Ensure comms is imported correctly
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@mui/material';
import PickDropDialog from './PickDropDialog.js'; // Import the new dialog component

function Towing() {
  const [trips, setTrips] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [dialogMode, setDialogMode] = useState('pick');
  const [options, setOptions] = useState({ colors: [], keyLocations: [], locations: [] });

  const driverAppSettings = JSON.parse(JSON.parse(localStorage.getItem('driverAppSettings'))) || {};

  useEffect(() => {
    fetchTrips();
  }, []);

  const fetchTrips = async () => {
    try {
      const res = await comms.getTripAssets();
      if (res.trips) {
        setTrips(res.trips);
        setOptions(res.options);
      } else {
        setTrips(res);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching trips:', error);
      setError(true);
      setLoading(false);
    }
  };

  const handlePickClick = (trip) => {
    let selected = trips.find(t => t.TripNo === trip);
    setSelectedTrip(selected);
    setDialogMode('pick');
    setDialogOpen(true);
  };

  const handleDropClick = (trip) => {
    let selected = trips.find(t => t.TripNo === trip);
    setSelectedTrip(selected);
    setDialogMode('drop');
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = (formData) => {
    console.log('Submitted data:', formData);

    const submitPromise = formData.mode === 'pick' ? comms.postTowing(formData) : comms.postTowingComplete(formData);

    submitPromise
      .then(async response => {
        console.log('Towing data successfully sent:', response);

        const res = await comms.getTripAssets();
        if (res.trips) {
          setTrips(res.trips);
          setOptions(res.options);
        } else {
          setTrips(res);
        }
      })
      .catch(error => {
        console.error('Error sending towing data:', error);
      });

    setDialogOpen(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Typography variant="h5" align="center">No Trips Found</Typography>
      </Grid>
    );
  }

  return (
    <div style={{ padding: '1rem', backgroundColor: '#f5f7fa' }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Typography variant="h6" align="left" gutterBottom>
            Choose a Trip
          </Typography>
        </Grid>
        {trips.map((trip, i) => {
          // Check if both pick and drop are complete and status is "On Lot"
          const isComplete = trip.VIN && trip.towTicket?.match(/\d+/g) && trip.status === 'On Lot';

          return isComplete ? (
            <Grid item xs={12} sm={6} key={i}>
              {/* Smaller version of the card */}
              <Card elevation={3} style={{ padding: '3px', backgroundColor: '#e0f7fa' }}>
                <CardContent style={{ padding: '3px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <div style={{ width: '40%' }} >
                      <Typography variant="h7" style={{ padding: '3px', paddingLeft:'10px' }}>
                        Trip #{trip.TripNo}
                      </Typography>

                    </div>
                    <div style={{ width: '55%' }} >
                      <Typography variant="h7" style={{ color: 'green', fontWeight: 'bold', }}>
                        Complete: {moment(trip.OriginScheduledDate).format('hh:mm a')}
                      </Typography>
                    </div>
             
                </CardContent>
              </Card>
            </Grid>
          ) : (
            <Grid item xs={12} sm={8} md={6} key={i}>
              <Card elevation={3}>
                <CardContent style={{ padding: '6px' }}> {/* Reduce CardContent padding */}
                  {/* Table Header */}
                  <Table style={{ marginBottom: '0' }}>
                    <TableBody>
                      <TableRow style={{ height: '20px' }}> {/* Adjusted height */}
                        <TableCell style={{ padding: '2px', borderBottom: 'none', fontWeight: 'bold' }}>
                          Trip #
                        </TableCell>
                        <TableCell style={{ padding: '2px', borderBottom: 'none', fontWeight: 'bold' }}>
                          Status
                        </TableCell>
                        <TableCell style={{ padding: '2px', borderBottom: 'none', fontWeight: 'bold' }}>
                          Time
                        </TableCell>
                      </TableRow>
                      {/* Table Data */}
                      <TableRow style={{ height: '25px' }}> {/* Adjusted height */}
                        <TableCell style={{ padding: '4px', borderBottom: 'none' }}>
                          {trip.TripNo}
                        </TableCell>
                        <TableCell style={{ padding: '4px', borderBottom: 'none' }}>
                          {trip.status || 'Pending'}
                        </TableCell>
                        <TableCell style={{ padding: '4px', borderBottom: 'none' }}>
                          {moment(trip.OriginScheduledDate).format('MM/DD hh:mm a')}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>

                  {/* Address and Pick/Drop Status */}
                  <Typography variant="body2" style={{ marginTop: '0.1rem', lineHeight: '1.2' }}> {/* Adjust margin and line height */}
                    {trip.Origin_AddressLine1} {trip.Origin_AddressLine2}
                  </Typography>

                  <div style={{ marginTop: '.1rem', display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <Typography variant="body2" component="span" color="textSecondary">
                        Pick:
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        color={trip.VIN ? 'primary' : 'error'}
                        style={{ fontWeight: 'bold', marginLeft: '5px' }}
                      >
                        <a href="#" onClick={() => handlePickClick(trip.TripNo)} style={{ textDecoration: 'none' }}>
                          {trip.VIN ? 'Complete' : 'Incomplete'}
                        </a>
                      </Typography>
                    </div>

                    <div>
                      <Typography variant="body2" component="span" color="textSecondary">
                        Drop:
                      </Typography>
                      <Typography
                        variant="body2"
                        component="span"
                        color={trip.towTicket?.match(/\d+/g) ? 'primary' : 'error'}
                        style={{ fontWeight: 'bold', marginLeft: '5px' }}
                      >
                        <a href="#" onClick={() => handleDropClick(trip.TripNo)} style={{ textDecoration: 'none' }}>
                          {trip.towTicket?.match(/\d+/g) ? 'Complete' : 'Incomplete'}
                        </a>
                      </Typography>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
      {selectedTrip && (
        <PickDropDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          tripDetails={selectedTrip}
          mode={dialogMode}
          options={options}
          onSubmit={handleDialogSubmit}
          googleApiKey={driverAppSettings.googleApiKey}
        />
      )}
    </div>
  );
}

export default Towing;
