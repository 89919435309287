import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Box, Paper, Grid, Fab } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import comms from './comms'; // Import comms module
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

function Login({ onLoginSuccess }) { // Add onLoginSuccess as a prop
  const [fleetCode, setFleetCode] = useState('');
  const [driverNumber, setDriverNumber] = useState('');
  const [fleetSettings, setFleetSettings] = useState({});
  const [driverAppSettings, setDriverAppSettings] = useState({});
  const [pin, setPin] = useState('');
  const [fleetName, setFleetName] = useState(''); // For fleet confirmation
  const [error, setError] = useState(null);
  const [showFleetCodeScreen, setShowFleetCodeScreen] = useState(false);
  const [showFleetConfirmation, setShowFleetConfirmation] = useState(false); // For fleet confirmation
  const navigate = useNavigate();

  // Check for existing fleet code when component mounts
  useEffect(() => {
    const storedFleetCode = localStorage.getItem('fleetCode');
    if (!storedFleetCode) {
      setShowFleetCodeScreen(true); // Show fleet code input screen if no fleet code is stored
    } else {
      setFleetSettings(JSON.parse(localStorage.getItem('fleetSettings')));
      
      setDriverAppSettings(JSON.parse(JSON.parse(localStorage.getItem('driverAppSettings'))));
      setFleetCode(storedFleetCode); // Use the stored fleet code
      const userSettings= JSON.parse(localStorage.getItem('userSettings'));
      if (userSettings?.Id){
        
      const driverSettings = JSON.parse(JSON.parse(localStorage.getItem('driverAppSettings')))
      console.log(driverSettings);
      if(driverSettings.dispatch){
        navigate('/dispatch');  // Navigate to the next page
      }else if (driverSettings.towing){
        navigate('/towing');  // Navigate to the next page
      }else if (driverSettings.cashiering){
        navigate('/balance');  // Navigate to the next page
      }
      }
    }
  }, []);

  // Handle fleet code submission
  const handleFleetCodeSubmit = async () => {
    try {
      const settings = await comms.getSettings(fleetCode);
      setFleetSettings(settings);
      setFleetName(settings.fleetName); // Assuming settings have fleetName
      setShowFleetConfirmation(true); // Show fleet confirmation
    } catch (error) {
      console.error('Fleet code retrieval failed:', error);
      setError('Failed to get settings, please check your Fleet Code.');
    }
  };

  // Handle fleet confirmation
  const handleFleetConfirmation = (isConfirmed) => {
    if (isConfirmed) {

      localStorage.setItem('fleetCode', fleetCode);
      setShowFleetCodeScreen(false); 
      setShowFleetConfirmation(false); // Proceed to login screen
    } else {
      // Return to fleet code input screen
      setShowFleetConfirmation(false);
    }
  };

  // Handle driver login
  const handleLogin = async () => {
    try {
      const res = await comms.authenticate(driverNumber, pin);
      const settings = await comms.getSettings(fleetCode);
      console.log(settings);
      setFleetSettings(settings);

      // Establish the Socket.IO connection
      const socket = comms.connectSocket(settings.dispatchUri);

      // Pass the socket to the parent component
      onLoginSuccess(socket);  // Callback to parent with the socket

      const driverSettings = JSON.parse(JSON.parse(localStorage.getItem('driverAppSettings')))
      console.log(driverSettings);
      if(driverSettings.dispatch){
        navigate('/dispatch');  // Navigate to the next page
      }else if (driverSettings.towing){
        navigate('/towing');  // Navigate to the next page
      }else if (driverSettings.cashiering){
        navigate('/balance');  // Navigate to the next page
      }

    } catch (error) {
      console.error('Login failed:', error);
      setError('Failed to log in, please check your credentials.');
    }
  };

  // Render fleet code input screen
  if (showFleetCodeScreen && !showFleetConfirmation) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item xs={12} md={4}>
        <Paper elevation={3} style={{ padding: '1rem', margin:'2rem' }}>
            <Typography variant="h5" align="center" gutterBottom>
              Welcome to Towing App
            </Typography>
            {error && <Typography color="error" align="center">{error}</Typography>}
            <TextField
              fullWidth
              label="Enter Fleet Code"
              value={fleetCode}
              onChange={(e) => setFleetCode(e.target.value)}
              margin="normal"
              variant="outlined"
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleFleetCodeSubmit}
              style={{ marginTop: '1rem' }}
            >
              Submit Fleet Code
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  // Render fleet confirmation screen
  if (showFleetConfirmation) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item xs={12} md={4}>
        <Paper elevation={3} style={{ padding: '1rem', margin:'2rem' }}>
            <Typography variant="h5" align="center" gutterBottom>
              Is this your fleet?
            </Typography>
            <Typography variant="h6" align="center" gutterBottom>
              {fleetName || 'Unknown Fleet'}
            </Typography>
            <Box display="flex" justifyContent="space-between" marginTop="2rem">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleFleetConfirmation(false)}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleFleetConfirmation(true)}
              >
                Yes
              </Button>
            </Box>

          </Paper>
        </Grid>
      </Grid>
    );
  }

  // Render login screen if fleet code is already stored
  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} md={4}>
        <Paper elevation={3} style={{ padding: '1rem', margin:'2rem' }}>
        {!!fleetSettings?.receiptLogo && 
        <div>
          <img src={fleetSettings?.receiptLogo} alt="Company Logo" style={{ width: '200px', marginBottom: '1rem', margin:'auto' }} />
        </div>
        
        }
         
          <Typography variant="h5" align="center" gutterBottom>
            Login
          </Typography>
          {error && <Typography color="error" align="center">{error}</Typography>}
          <TextField
            fullWidth
            label="Driver Number"
            value={driverNumber}
            onChange={(e) => setDriverNumber(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Pin"
            type="password"
            value={pin}
            onChange={(e) => setPin(e.target.value)}
            margin="normal"
            variant="outlined"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleLogin}
            style={{ marginTop: '1rem' }}
          >
            Login
          </Button>
        </Paper>
        <Button
            variant="text"
          
            onClick={()=>{
               localStorage.removeItem('fleetSettings')
               localStorage.removeItem('driverAppSettings')
               localStorage.removeItem('fleetCode')
               localStorage.removeItem('userSettings')
              window.location.href='/'
            }}
            style={{ marginTop: '1rem' }}
          >
            <SettingsOutlinedIcon 
            />
          </Button>
      </Grid>
      
    </Grid>
  );
}

export default Login;
