// comms.js
import axios from 'axios';
import io from 'socket.io-client';

const comms = {
    authenticate: (driverNumber, pin) => {
        const fleetSettings = JSON.parse(localStorage.getItem('fleetSettings')) || {};
        const req = {
            driverNumber,
            pin: pin.toString().padStart(4, "0"),
            unitId: fleetSettings.unitId,
        };

        return axios.post(`${fleetSettings.dispatchUri}/API/Driver/Auth`, req)

            .then((res) => {
                if(res.error){
                    console.error('Authentication failed:', res.error);

                    throw res.error;
                }else{
                    localStorage.setItem('userSettings', JSON.stringify(res.data));
                    return res.data;
                }
            })
            .catch((err) => {
                console.error('Authentication failed:', err);

                throw err;
            });
    },
    getSettings: (fleetCode) => {
        const req = { unitId: fleetCode };
        return axios.post('https://ta2config.tranware.net/endpoint.php', req)
            .then((res) => {
                localStorage.setItem('fleetSettings', JSON.stringify(res.data));
                localStorage.setItem('driverAppSettings', JSON.stringify(res.data.driverAppConfig));
                return res.data;
            })
            .catch((err) => {
                console.error('Settings retrieval failed:', err);
                throw err;
            });
    },
    getTripAssets: function (vin) {

        if (!vin || vin == 'undefined') {
            vin = '';
        }
        const user = JSON.parse(localStorage.getItem('userSettings'));
        console.log("comms.future req:", "/API/Driver/TripAsset?paxId=" + user.Id + "&vin=" + vin || '');
        const fleetSettings = JSON.parse(localStorage.getItem('fleetSettings')) || {};
        return fetch(fleetSettings.dispatchUri + "/API/Driver/TripAsset?paxId=" + user.Id + "&vin=" + vin || '')
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                return (data)
            })
            .catch((error) => {
                console.error('Error fetching trips:', error);
                throw error;
            });

    },
    connectSocket: (dispatchUri) => {
        const socket = io(dispatchUri); // Using the Socket.IO client via npm version 2.1.0
        socket.on('connect', () => {
            console.log('Socket.IO connected');
        });
        socket.on('message', (msg) => {
            console.log('Received message:', msg);
        });
        return socket;
    },
    postTowing: function (data) {
        const fleetSettings = JSON.parse(localStorage.getItem('fleetSettings')) || {};

        const req = data;
        console.log("comms.postTowing req:", req);

        return axios.post(`${fleetSettings.dispatchUri}/API/Driver/Towing`, req, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log("comms.postTowing res:", res.data);
                if (!res.data.error) {
                    return res.data;
                } else {
                    throw new Error(res.data.error);
                }
            })
            .catch((error) => {
                console.error('Error posting towing data:', error.message || error);
                throw error;
            });
    },
    postTowingComplete: function (data) {
        const fleetSettings = JSON.parse(localStorage.getItem('fleetSettings')) || {};

        const req = data;
        console.log("comms.postTowing req:", req);

        return axios.post(`${fleetSettings.dispatchUri}/API/Driver/TowingComplete`, req, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then((res) => {
                console.log("comms.postTowing res:", res.data);
                if (!res.data.error) {
                    return res.data;
                } else {
                    throw new Error(res.data.error);
                }
            })
            .catch((error) => {
                console.error('Error posting towing data:', error.message || error);
                throw error;
            });
    },
    getInspectionItems: async () => {
        try {
          const user = JSON.parse(localStorage.getItem('userSettings')) || {};
          const fleet = JSON.parse(localStorage.getItem('fleetSettings')) || {};
          
          const req = {
            driverId: user.Id,
            vehicleId: user.VehicleID,
          };
    
          console.log("comms.getInspectionItems req:", req);
    
          const response = await axios.get(`${fleet.dispatchUri}/API/Driver/Inspection`, {
            params: { id: user.Id },
            headers: {
              'Content-Type': 'application/json',
            },
          });
    
          console.log("comms.getInspectionItems res:", response.data);
          if (!response.data.error) {
            return response.data;
          } else {
            throw new Error(response.data.error);
          }
    
        } catch (error) {
          console.error("comms.getInspectionItems error:", error);
          throw error;
        }
      },
    postInspectionItems: async (data) => {
    try {
        const user = JSON.parse(localStorage.getItem('userSettings')) || {};
        const fleet = JSON.parse(localStorage.getItem('fleetSettings')) || {};

        const req = {
        ...data,
        driverId: user.Id,
        vehicleId: user.VehicleID,
        };

        console.log("comms.postInspectionItems req:", req);

        const response = await axios.post(`${fleet.dispatchUri}/API/Driver/Inspection`, req, {
        headers: {
            'Content-Type': 'application/json',
        },
        });

        console.log("comms.postInspectionItems res:", response.data);
        if (!response.data.error) {
        return response.data;
        } else {
        throw new Error(response.data.error);
        }

    } catch (error) {
        console.error("comms.postInspectionItems error:", error);
        throw error;
    }
    }



};



export default comms;
