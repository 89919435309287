import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Logout() {
  const navigate = useNavigate();

  // Clear user data and redirect to login screen
  useEffect(() => {
    // Clear all user-related localStorage items
    localStorage.removeItem('userSettings');

    
    // Redirect to login page after clearing data
    navigate('/login'); // Assuming '/login' is your login route
  }, [navigate]);

  return null; // No UI required for logout
}

export default Logout;