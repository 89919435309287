import React from 'react';

function Future() {
  return (
    <div>
      <h2>Future Trips</h2>
      <p>Details of upcoming trips will be shown here.</p>
    </div>
  );
}

export default Future;
