import React, { useState, useEffect } from 'react';
import axios from 'axios'
import { decodeVIN, splitVIN, validateVIN } from 'universal-vin-decoder';
import PhotoIcon from '@mui/icons-material/AddAPhoto';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import { Camera } from '@mui/icons-material';

// Styled components for compact layout
const CompactTextField = styled(TextField)({
  margin: '2px 0', // Reducing margin
  '& .MuiInputBase-root': {
    fontSize: '0.8rem', // Smaller text
    padding: '2px 2px', // Reducing padding
  },
  '& .MuiInputBase-input': {
    fontSize: '0.8rem', // Smaller text
    padding: '2px 2px', // Remove padding from the input field itself
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.75rem', // Smaller label
  },
});

const CompactSelect = styled(Select)({
  margin: '4px 0',
  '& .MuiSelect-select': {
    fontSize: '0.8rem',
    padding: '2px 5px',
  },
});

const CompactTypography = styled(Typography)({
  fontSize: '0.75rem',
  margin: '4px 0',
});

function PickDropDialog({ open, onClose, tripDetails, mode, options, onSubmit, googleApiKey }) {
  const [vin, setVin] = useState(tripDetails?.VIN || '');
  const [tag, setTag] = useState(tripDetails?.tag || '');
  const [tagState, setTagState] = useState(tripDetails?.tagState || '');
  const [unitNumber, setUnitNumber] = useState(tripDetails?.unitNumber || '');
  const [color, setColor] = useState(tripDetails?.color || '');
  const [odometer, setOdometer] = useState(tripDetails?.odometer || '');
  const [notes, setNotes] = useState('');
  const [stallNumber, setStallNumber] = useState('');
  const [keyLocation, setKeyLocation] = useState('');
  const [ticketNumber, setTicketNumber] = useState('');
  const [assetLocation, setAssetLocation] = useState('');
  const [vinImage, setVinImage] = useState(null); // Handle image upload for VIN
  const [damageImages, setDamageImages] = useState([]); // Handle general images upload
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  useEffect(() => {
    if (tripDetails) {
      setVin(tripDetails.VIN || '');
      setTag(tripDetails.tag || '');
      setTagState(tripDetails.tagState || '');
      setUnitNumber(tripDetails.unitNumber || '');
      setColor(tripDetails.color || '');
      setOdometer(tripDetails.odometer || '');
      setNotes(tripDetails.notes || '');
      setStallNumber(tripDetails.stall || '');
      setKeyLocation(tripDetails.keyLocation || '');
      setTicketNumber(tripDetails.towTicket || '');
      setAssetLocation(tripDetails.location || '');
      setIsSubmitDisabled(true)
      setVinImage(null);
      setDamageImages([]);
    }
  }, [tripDetails]);

  function readVinImage(e) {
    const file = e.target.files[0];
    if (file) {

      var reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          // Dynamically create a canvas element
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Resize the image to fit the canvas

          var aspectRatio = img.height / img.width;
          var newWidth = 300; // Resize to 300px wide
          var newHeight = newWidth * aspectRatio;

          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
          // Convert the canvas to a base64 data URL
          const dataUrl = canvas.toDataURL(file.type);

          // Show the resized image in the state (for preview purposes)
          setVinImage(dataUrl);

          // Extract the base64 content and send to Google OCR
          performOcr(dataUrl.split(',')[1]);
        };
      };

      reader.readAsDataURL(file);
    }
  }

  const performOcr = async (base64Image) => {
    const googleQuery = {
      requests: [
        {
          image: {
            content: base64Image,
          },
          features: [
            {
              type: 'TEXT_DETECTION',
            },
          ],
        },
      ],
    };

    try {
      const response = await axios.post(
        `https://vision.googleapis.com/v1/images:annotate?key=${googleApiKey}`,
        googleQuery,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Detected Text:', response.data.responses[0].fullTextAnnotation?.text);
      const vinText = response.data.responses[0].fullTextAnnotation?.text?.replace(/\s+/g, '') || '';
      setVin(vinText); // Set the detected VIN in the input field
      console.log('Detected VIN:', vinText);

      validateSubstringsForVIN(vinText);

    } catch (error) {
      console.error('Error with OCR:', error);
    }
  };

  const validateSubstringsForVIN = (detectedText) => {
    let validVin = '';

    for (let i = 0; i < detectedText.length; i++) {
      for (let j = i + 14; j <= detectedText.length; j++) {
        const substring = detectedText.substring(i, j);
        const validation = validateVIN(substring);

        if (validation.isValid) {
          console.log('Valid VIN found:', substring);
          validVin = substring;
          setVin(substring);
          console.log(decodeVIN(validVin))
          return;
        }
      }
    }

    if (!validVin) {
      console.log('No valid VIN found.');
    }
  };

  const handleDamageImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const imagePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            // Dynamically create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            var aspectRatio = img.height / img.width;
            var newWidth = 300; // Resize to 300px wide
            var newHeight = newWidth * aspectRatio;

            canvas.width = newWidth;
            canvas.height = newHeight;

            // Draw the image on the canvas with the new dimensions
            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            // Convert the canvas to a data URL

            const dataUrl = canvas.toDataURL(file.type);

            // Resolve the promise with the image URL
            resolve(dataUrl);
          };

          img.onerror = (error) => {
            reject(error);
          };
        };

        reader.readAsDataURL(file);
      });
    });

    // Wait for all image processing to complete
    Promise.all(imagePromises).then((uploadedImages) => {
      console.log(uploadedImages);
      setDamageImages((prevImages) => [...prevImages, ...uploadedImages]);
    }).catch((error) => {
      console.error("Error processing images:", error);
    });
  };

  useEffect(() => {
    // Validation logic to enable/disable submit button
    if (mode === 'pick') {
      setIsSubmitDisabled(!vin || !odometer);
    } else if (mode === 'drop') {
      setIsSubmitDisabled(!ticketNumber );
    }
  }, [vin, ticketNumber, odometer, mode]);
  
  const sanitizeInput = (input) => {
    return input.replace(/[/'/]/g, '');
  };

  const handleSubmit = () => {
    const colorId=options.colors.find((item) => item.name === color)?.id || null;
    const locationId=options.locations.find((item) => item.name === assetLocation)?.id || null;
    const keyLocationId=options.keyLocations.find((item) => item.name === keyLocation)?.id || null;
    const typeId=options.keyLocations.find((item) => item.name === keyLocation)?.id || null;
    const state=tagState.toUpperCase().substring(0,2)
    console.log(options.colors)
    const payload = {
      mode,
      vin: sanitizeInput(vin),
      tag: sanitizeInput(tag),
      tagState: sanitizeInput(state),
      unitNumber,
      color,
      colorId:colorId,
      odometer,
      notes: sanitizeInput(notes),
      stall: sanitizeInput(stallNumber),
      keyLocation: keyLocationId,
      ticket: sanitizeInput(ticketNumber),
      location:locationId, 
      locationId,
      vinImage,
    };
    if (!!damageImages.length || !!vinImage )
    {
      console.log(damageImages)
      if(!!damageImages.length && !!vinImage){
        payload.images= [...damageImages, vinImage]
      }else if(!!damageImages.length && !vinImage){
        payload.images= [...damageImages]
      }if(!damageImages.length && !!vinImage){
        payload.images= [vinImage]
      }
    } 
    onSubmit({ ...tripDetails, ...payload, VIN: vin, type:null });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <CompactTypography variant="h6" gutterBottom>
          {tripDetails?.make} {tripDetails?.model}  {tripDetails?.reason}
        </CompactTypography>
        {mode === 'pick' && (
          <>
            {/* VIN Field with PIC and TYPE buttons */}
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>

              <div style={{ display: 'none', }}>
                <input
                  id="vinImageInput"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={readVinImage}
                />
              </div>
            </div>

            {/* License Number, Unit Number, Color, and Odometer in two-column layout */}
            <Grid container spacing={1} alignItems="center">
              {/* License Number */}
              <Grid item xs={5}>
                <CompactTypography variant="subtitle1">
                  VIN <span style={{color:'red'}}>*</span>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => document.getElementById('vinImageInput').click()}
                  >
                    <PhotoIcon />
                  </Button>
                </CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="Vehicle ID Number"
                  value={vin}
                  onChange={(e) => setVin(e.target.value)}
                  fullWidth
                />
              </Grid>
              {/* License Number */}
              <Grid item xs={5}>
                <CompactTypography>Tag</CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="License Plate #"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  fullWidth
                />
              </Grid>
              {/* License Number */}
              <Grid item xs={5}>
                <CompactTypography>Registration State</CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="State"
                  value={tagState}
                  onChange={(e) => setTagState(e.target.value)}
                  fullWidth
                />
              </Grid>
              {/* Unit Number */}
              <Grid item xs={5}>
                <CompactTypography>Unit No</CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="Fleet Id #"
                  value={unitNumber}
                  onChange={(e) => setUnitNumber(e.target.value)}
                  fullWidth
                />
              </Grid>

              {/* Color Selection */}
              <Grid item xs={5}>
                <CompactTypography>Color</CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <CompactSelect value={color} onChange={(e) => setColor(e.target.value)}>
                    {options.colors.map((colorOption) => (
                      <MenuItem key={colorOption.id} value={colorOption.name}>
                        {colorOption.name}
                      </MenuItem>
                    ))}
                  </CompactSelect>
                </FormControl>
              </Grid>

              {/* Odometer */}
              <Grid item xs={5}>
                <CompactTypography>Mileage<span style={{color:'red'}}>*</span></CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="Don't include tenths digit"
                  type="number"
                  value={odometer}
                  onChange={(e) => setOdometer(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>

            {/* Notes */}
            <CompactTypography style={{ marginTop: '8px' }}>Notes</CompactTypography>
            <CompactTextField
              placeholder="Include any descriptions"
              multiline
              rows={2}
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
            />
          </>
        )}
        {/* Additional fields for Drop mode */}
        {mode === 'drop' && (
          <>
            {/* Stall Number */}
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={5}>
                <CompactTypography>Stall Location </CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="Parking spot"
                  value={stallNumber}
                  onChange={(e) => setStallNumber(e.target.value)}
                  fullWidth
                />
              </Grid>

              {/* Key Location */}
              <Grid item xs={5}>
                <CompactTypography>Asset Key Location</CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <CompactSelect
                    value={keyLocation}
                    onChange={(e) => setKeyLocation(e.target.value)}
                  >
                    {options.keyLocations.map((location) => (
                      <MenuItem key={location.id} value={location.name}>
                        {location.name}
                      </MenuItem>
                    ))}

                  </CompactSelect>
                </FormControl>
              </Grid>

              {/* Tow Ticket */}
              <Grid item xs={5}>
                <CompactTypography>Ticket #<span style={{color:'red'}}>*</span></CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <CompactTextField
                  placeholder="Ticket Number"
                  value={ticketNumber}
                  onChange={(e) => setTicketNumber(e.target.value)}
                  fullWidth
                />
              </Grid>

              {/* Asset Location */}
              <Grid item xs={5}>
                <CompactTypography>Asset Location</CompactTypography>
              </Grid>
              <Grid item xs={7}>
                <FormControl fullWidth>
                  <CompactSelect
                    value={assetLocation}
                    onChange={(e) => setAssetLocation(e.target.value)}
                  >
                    {options.locations.map((location) => (
                      <MenuItem key={location.id} value={location.name}>
                        {location.name}
                      </MenuItem>
                    ))}
                  </CompactSelect>
                </FormControl>
              </Grid>
            </Grid>
          </>
        )}


        {/* Images upload section */}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
          <CompactTypography>Images</CompactTypography>
          <Button
            variant="text"
            color="primary"
            onClick={() => document.getElementById('damageImageInput').click()}
          >
            TAKE PICS
          </Button>
          <input
            id="damageImageInput"
            type="file"
            accept="image/*"
            multiple
            style={{ display: 'none' }}
            onChange={handleDamageImageUpload}
          />
        </div>
        {/* Display uploaded images */}
        {(damageImages.length > 0 || vinImage) && (
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
            {vinImage && (
              <img
                key="vinimage"
                src={vinImage}
                alt="vin-image"
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            )}
            {damageImages.map((src, idx) => (
              <img
                key={idx}
                src={src}
                alt={`damage-${idx}`}
                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
              />
            ))}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="warning">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary"  disabled={isSubmitDisabled}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PickDropDialog;
