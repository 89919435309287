import React from 'react';

function Payments() {
  return (
    <div>
      <h2>Payments</h2>
      <p>Handle payments and fares here.</p>
    </div>
  );
}

export default Payments;
