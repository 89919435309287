import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';

function Header({ onMenuClick, onRefreshClick }) {


  return (
    <AppBar position="static" style={{ backgroundColor: '#3f51b5' }}>
      <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={onMenuClick}>
            <MenuIcon />
          </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          towingapp.net
        </Typography>
        <IconButton color="inherit" aria-label="refresh" onClick={onRefreshClick}>
          <RefreshIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
