import React from 'react';

function History() {
  return (
    <div>
      <h2>Past Trips</h2>
      <p>Details of past trips will be shown here.</p>
    </div>
  );
}

export default History;
