import React from 'react';

function Dispatch() {
  return (
    <div>
      <h2>Dispatch</h2>
      <p>Here will be the job details for the driver.</p>
    </div>
  );
}

export default Dispatch;
