import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Logout from './components/Logout';
import Dispatch from './components/Dispatch';
import Map from './components/Map';
import Payments from './components/Payments';
import Chat from './components/Chat';
import History from './components/History';
import Future from './components/Future';
import Balance from './components/Balance'; // Add the new components
import Transactions from './components/Transactions';
import Deposits from './components/Deposits';
import Stickers from './components/Stickers';
import Towing from './components/Towing';
import Inspection from './components/Inspection';
import Help from './components/Help';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
function App() {

  const [socket, setSocket] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    const userSettings = localStorage.getItem('userSettings');
    if(!userSettings){
      return;
    }
    setSidebarOpen(!sidebarOpen);
  };

  const handleRefreshClick = () => {
    window.location.href='/'
  };

  const handleLoginSuccess = (socketInstance) => {
    // Store the socket object
    setSocket(socketInstance);
    console.log('Socket connected:', socketInstance);
  };

  return (
    <Router>
      <Header onMenuClick={toggleSidebar} onRefreshClick={handleRefreshClick} />
      <Sidebar open={sidebarOpen} onClose={toggleSidebar} />

      <Routes>
        <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />} />
        <Route path="/dispatch" element={<Dispatch />} />
        <Route path="/towing" element={<Towing />} />
        <Route path="/inspection" element={<Inspection />} />
        <Route path="/map" element={<Map />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/history" element={<History />} />
        <Route path="/future" element={<Future />} />
        <Route path="/balance" element={<Balance />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/deposits" element={<Deposits />} />
        <Route path="/stickers" element={<Stickers />} />
        <Route path="/help" element={<Help />} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/" element={<Login onLoginSuccess={handleLoginSuccess} />} />
      </Routes>
    </Router>
  );
}

export default App;