import React, { useState, useEffect } from 'react';
import { Button, TextField, Select, MenuItem, FormControl, Typography, Grid, Switch } from '@mui/material';
import { styled } from '@mui/system';
import comms from './comms'; // Import your comms file

// Styled components
const CompactTextField = styled(TextField)({
  margin: '2px 0',
  backgroundColor: 'white',
  '& .MuiInputBase-root': {
    fontSize: '0.8rem',
    padding: '2px 2px',
  },
  '& .MuiInputBase-input': {
    fontSize: '0.8rem',
    padding: '2px 2px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '0.75rem',
  },
});

const CompactSelect = styled(Select)({
  margin: '4px 0',
  backgroundColor: 'white',
  '& .MuiSelect-select': {
    fontSize: '0.8rem',
    padding: '2px 5px',
  },
});

const CompactTypography = styled(Typography)({
  fontSize: '.8rem', // Increased size for labels
  margin: '6px 0',
});

const TitleTypography = styled(Typography)({
  fontSize: '1.5rem', // Larger title size
  fontWeight: 'bold',
  margin: '16px 0',
});

const SubmitButton = styled(Button)({
  position: 'fixed',
  bottom: '20px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '90%',
});

function InspectionForm() {
  const [formData, setFormData] = useState({});
  const [inspectionFields, setInspectionFields] = useState([]); // Will hold fields from the API response
  const [shiftId, setShiftId] = useState(null);
  const [images, setImages] = useState([]); // Handle images separately
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true); // State to disable submit button

  // Fetch inspection data dynamically
  useEffect(() => {
    comms.getInspectionItems()
      .then((res) => {
        if (!!res.status) {
          const data = res.data;
          setInspectionFields(data);
          setShiftId(res.shiftId);
          const initialData = {};
          data.forEach((field, i) => {
            initialData[`input-${i}`] = field.type === 'boolean' ? false : ''; // Set default values
          });
          setFormData(initialData);
        }
      })
      .catch((err) => console.error("Error fetching inspection items:", err));
  }, []);

  // Validate required fields
  useEffect(() => {
    validateRequiredFields();
  }, [formData, images]); // Re-run validation every time formData or images change

  // Handle dynamic input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const handleFileUpload = (e, index) => {
    const files = Array.from(e.target.files);
    const imagePromises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const aspectRatio = img.height / img.width;
            const newWidth = 300;
            const newHeight = newWidth * aspectRatio;

            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(img, 0, 0, newWidth, newHeight);

            const dataUrl = canvas.toDataURL(file.type);
            resolve(dataUrl);
          };

          img.onerror = (error) => reject(error);
        };

        reader.readAsDataURL(file);
      });
    });

    // Update the images state by appending new images instead of replacing them
    Promise.all(imagePromises).then((uploadedImages) => {
      setImages((prev) => [...prev, ...uploadedImages]); // Append new images to the state
      setFormData((prev) => ({
        ...prev,
        [`input-${index}`]: [...(prev[`input-${index}`] || []), ...uploadedImages] // Append to the specific input
      }));
    }).catch((error) => {
      console.error("Error processing images:", error);
    });
  };

  // Function to validate required fields
  const validateRequiredFields = () => {
    let isComplete = true;
    inspectionFields.forEach((field, i) => {
      if (field.required && !formData[`input-${i}`]) {
        isComplete = false;
      }
    });
    console.log(isComplete)
    // Disable the submit button if any required fields are incomplete
    setIsSubmitDisabled(!isComplete);
  };

  // Handle form submission (adapted from the old submitInspection handler)
  const handleSubmit = () => {
    let data = [...inspectionFields];
    let complete = true;

    // Loop through each inspection field and validate
    data.forEach((field, i) => {
      if (field.type === 'boolean') {
        data[i].result = formData[`input-${i}`] || false;
      } else if (['number', 'text', 'select', 'textField'].includes(field.type)) {
        data[i].result = formData[`input-${i}`] || '';
      } else if (field.type === 'image') {
        // Images have already been handled in formData
        data[i].result = formData[`input-${i}`] || [];
      }

      // Validate required fields
      if (field.required && !data[i].result) {
        complete = false;
      }
    });

    if (!complete) {
      console.log("Inspection not completed");
      // Display a warning (e.g., using a snackbar or alert)
    } else {
      console.log("Submitting inspection...");
      const user = JSON.parse(localStorage.getItem('userSettings')) || {};
      const req = {
        id: user.Id, // Use real ID from user settings
        shiftId,
        data: data,
      };

      // Call the POST API to submit inspection
      comms.postInspectionItems(req).then(function (res) {
        console.log(res);
        // Handle success (e.g., navigate back to towing or show a success message)
      }).catch((error) => {
        console.error("Error submitting inspection:", error);
      });
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto', position: 'relative' }}>
      <TitleTypography variant="h4" gutterBottom>
        Vehicle Inspection
      </TitleTypography>

      <Grid container spacing={2}>
        {inspectionFields.map((field, index) => (
          <React.Fragment key={index}>
            <Grid item xs={5}>
              <CompactTypography>
                {field.name} {!!field.required && <span style={{ color: 'red' }}>*</span>}
              </CompactTypography>
            </Grid>
            <Grid item xs={7}>
              {field.type === 'boolean' ? (
                <Switch
                  name={`input-${index}`}
                  checked={formData[`input-${index}`]}
                  onChange={handleCheckboxChange}
                  color="primary"
                />
              ) : field.type === 'number' ? (
                <CompactTextField
                  name={`input-${index}`}
                  type="number"
                  value={formData[`input-${index}`]}
                  onChange={handleInputChange}
                  fullWidth
                  inputProps={{
                    min: field.min,
                    max: field.max,
                  }}
                />
              ) : field.type === 'select' ? (
                <FormControl fullWidth>
                  <CompactSelect
                    value={formData[`input-${index}`]}
                    name={`input-${index}`}
                    onChange={handleInputChange}
                  >
                    <MenuItem value=""></MenuItem>
                    {field.description.split(',').map((option, idx) => (
                      <MenuItem key={idx} value={option}>{option}</MenuItem>
                    ))}
                  </CompactSelect>
                </FormControl>
              ) : field.type === 'text' ? (
                <CompactTextField
                  name={`input-${index}`}
                  type="text"
                  value={formData[`input-${index}`]}
                  onChange={handleInputChange}
                  fullWidth
                />
              ) : field.type === 'textField' ? (
                <CompactTextField
                  name={`input-${index}`}
                  value={formData[`input-${index}`]}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  rows={3}
                />
              ) : field.type === 'image' ? (
                <>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => document.getElementById(`imageInput-${index}`).click()}
                  >
                    Take Pics
                  </Button>
                  <input
                    type="file"
                    id={`imageInput-${index}`}
                    accept="image/*"
                    multiple
                    hidden
                    onChange={(e) => handleFileUpload(e, index)}
                  />
                </>
              ) : null}
            </Grid>
          </React.Fragment>
        ))}
        {inspectionFields.length > 1 && (
          <>
        <Grid item xs={12}>
          {images.length > 0 && (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', marginTop: '10px' }}>
              {images.map((src, idx) => (
                <img
                  key={idx}
                  src={src}
                  alt={`damage-${idx}`}
                  style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                />
              ))}
            </div>
          )}
        </Grid>
        
        <Grid item xs={12}>
          <SubmitButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled} // Disable if required fields aren't completed
            fullWidth
          >
            Submit Inspection
          </SubmitButton>
        </Grid>
        </>
        )}
      </Grid>
    </div>
  );
}

export default InspectionForm;
