import React from 'react';

function Map() {
  return (
    <div>
      <h2>Map</h2>
      <p>Map showing vehicle location and trips will be displayed here.</p>
    </div>
  );
}

export default Map;
