// Sidebar.js
import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import DepositIcon from '@mui/icons-material/ArrowUpward';
import PaymentIcon from '@mui/icons-material/ArrowDownward';
import DispatchIcon from '@mui/icons-material/MinorCrash';
import TripIcon from '@mui/icons-material/Commute';
import ChatIcon from '@mui/icons-material/Chat';
import TowingIcon from '@mui/icons-material/CarRepair';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import HistoryIcon from '@mui/icons-material/History';
import FutureIcon from '@mui/icons-material/Update';
import BadgeIcon from '@mui/icons-material/LocalPolice';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import Divider from '@mui/material/Divider';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import {
    Card,
    CardContent,
    Typography,
    Grid,
    Table,
    TableBody,
    TableRow,
    TableCell,
  } from '@mui/material';

function Sidebar({ open, onClose }) {

    const [permissions, setPermissions] = useState({});
    const [user, setUser] = useState({});
    const [fleetSettings, setFleetSettings] = useState({});

    useEffect(() => {
      // Retrieve the permissions from localStorage
      const storedSettings  = JSON.parse(JSON.parse(localStorage.getItem('driverAppSettings')) )|| {};
      const fleetSettings  = JSON.parse(localStorage.getItem('fleetSettings') )|| {};
      const userSettings  = JSON.parse(localStorage.getItem('userSettings') )|| {};
      setPermissions(storedSettings); // Assuming permissions structure is inside driverAppConfig
        setUser(userSettings);
        setFleetSettings(fleetSettings);
    }, [open]);

    const links = [
        { to: '/dispatch', text: 'Dispatch', icon: <DispatchIcon />, permission: 'dispatch' },
        { to: '/balance', text: 'Balance', icon: <AccountBalanceIcon />, permission: 'cashiering' },
        { to: '/transactions', text: 'Transactions', icon: <ReceiptLong />, permission: 'transactions' },
        { to: '/deposits', text: 'Deposits', icon: <DepositIcon />, permission: 'deposits' },
        { to: '/payments', text: 'Payments', icon: <PaymentIcon />, permission: 'payments' },
        { to: '/towing', text: 'Towing', icon: <ConstructionOutlinedIcon />, permission: 'towing' },
        { to: '/inspection', text: 'Inspection', icon: <TowingIcon />, permission: 'inspection' },
        { to: '/chat', text: 'Chat', icon: <ChatIcon />, permission: 'chat' },
        { to: '/history', text: 'History', icon: <HistoryIcon />, permission: 'history' },
        { to: '/future', text: 'Future', icon: <FutureIcon />, permission: 'future' },
        { to: '/stickers', text: 'Stickers', icon: <BadgeIcon />, permission: 'stickers' },
        { to: '/logout', text: 'Logout', icon: <ExitToAppOutlinedIcon />, permission: 'displayZones' },
      ];

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
        <div style={{marginTop:'20px'}}>
          <img src={fleetSettings?.receiptLogo} alt="Company Logo" style={{ width: '80px',  marginBottom: '1rem', margin:'auto' }} />
        </div>
         <Typography variant="h6" align="center" gutterBottom gutterTop>
         {user.LastName}, {user.FirstName}
         </Typography>
         <Divider orientation="horizontal" variant="middle" flexItem />
      <List>
        {links.map((link, index) => (
             permissions[link.permission] &&(
          <ListItem button component={Link} to={link.to} key={index} onClick={onClose}>
            <ListItemIcon>{link.icon}</ListItemIcon>
            <ListItemText primary={link.text} />
          </ListItem>
             )
        ))}
      </List>
    </Drawer>
  );
}

export default Sidebar;
